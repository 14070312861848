import * as Sentry from "@sentry/react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import { CacheProvider } from "./cache-provider";
import AuthGuard from "./components/auth/auth-guard";
import RedirectWithToken from "./components/auth/redirect-with-token";
import SalesforceGuard from "./components/salesforce/salesforce-guard";
import { TaskDrawerProvider } from "./components/task-drawer";
import {
  ALL_TASKS,
  CLEANING_TASKS,
  DELIVERY_TASKS,
  DROP_STRIPS_TASKS,
  ENGINEERING_TASKS,
  FINAL_INSPECTION_TASKS,
  GLASS_LANAI_TASKS,
  GUEST_EXPERIENCE_TASKS,
  HOUSEKEEPING_TASKS,
  MAINTENANCE_INSPECTION_TASKS,
  MY_TASKS,
  OVERDUE_TASKS,
  OWNER_EXPERIENCE_TASKS,
  SHUTTLE_SERVICE_TASKS,
  SPECIAL_PROJECT_TASKS,
  UNASSIGNED_TASKS,
  TasksPageParams,
} from "./config/path-config";
import { api_keys, environment } from "./config";
import { AppLayout } from "./layout/app-layout";
import {
  TasksPage,
  HomePage,
  ListingPage,
  ListingsPage,
  TaskPage,
  UnknownRoutePage,
  UserPage,
  ReservationsPage,
} from "./page";
import { clientVersion } from "./utils/version";
import "./tailwind.css";
import ReservationModalProvider from "./components/reservation-modal";
import BrandGuard from "./components/brand/brand-guard";
import brand from "./config/brand";
import i18n from "../i18n/config";

export const internalVersion = 8; // bump for remote build tests

const sentryEnv = environment.mrrEnv ? environment.mrrEnv : "production";

Sentry.init({
  environment: sentryEnv,
  dsn: api_keys.sentry_dsn,
  release: clientVersion,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  // debug: true,

  replaysOnErrorSampleRate: 1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: sentryEnv === "development" ? 1.0 : 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

const AppRouter = () => {
  // Task Routes are build dynamically based on the brand configuration.
  // Additions to the brand.listViews should be added here and in config.
  const tasksRoutes: RouteObject[] = [
    { index: true, path: ":taskId", element: <TaskPage /> },
    {
      index: true,
      path: "",
      element: <TasksPage taskParam={TasksPageParams.All} />,
    },
  ];
  if (brand.listTaskViews.includes(MY_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: MY_TASKS,
      element: <TasksPage taskParam={TasksPageParams.My} />,
    });
  }
  if (brand.listTaskViews.includes(ALL_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: ALL_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(UNASSIGNED_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: UNASSIGNED_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Unassigned} />,
    });
  }
  if (brand.listTaskViews.includes(OVERDUE_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: OVERDUE_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Overdue} />,
    });
  }
  if (brand.listTaskViews.includes(DROP_STRIPS_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: DROP_STRIPS_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(GLASS_LANAI_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: GLASS_LANAI_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(MAINTENANCE_INSPECTION_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: MAINTENANCE_INSPECTION_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(CLEANING_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: CLEANING_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(FINAL_INSPECTION_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: FINAL_INSPECTION_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(SPECIAL_PROJECT_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: SPECIAL_PROJECT_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(DELIVERY_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: DELIVERY_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(SHUTTLE_SERVICE_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: SHUTTLE_SERVICE_TASKS,
      element: <TasksPage taskParam={TasksPageParams.All} />,
    });
  }
  if (brand.listTaskViews.includes(ENGINEERING_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: ENGINEERING_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Unassigned} />,
    });
  }
  if (brand.listTaskViews.includes(HOUSEKEEPING_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: HOUSEKEEPING_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Unassigned} />,
    });
  }
  if (brand.listTaskViews.includes(GUEST_EXPERIENCE_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: GUEST_EXPERIENCE_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Unassigned} />,
    });
  }
  if (brand.listTaskViews.includes(OWNER_EXPERIENCE_TASKS)) {
    tasksRoutes.push({
      index: true,
      path: OWNER_EXPERIENCE_TASKS,
      element: <TasksPage taskParam={TasksPageParams.Unassigned} />,
    });
  }
  return useRoutes(AppRoutes(tasksRoutes));
};

export const AppRoutes: (taskRoutes: RouteObject[]) => RouteObject[] = (
  tasksRoutes
) => [
  {
    path: "",
    element: (
      <AuthGuard>
        <SalesforceGuard>
          <CacheProvider>
            <BrandGuard>
              <TaskDrawerProvider>
                <ReservationModalProvider>
                  <AppLayout />
                </ReservationModalProvider>
              </TaskDrawerProvider>
            </BrandGuard>
          </CacheProvider>
        </SalesforceGuard>
      </AuthGuard>
    ),
    children: [
      { index: true, path: "", element: <Navigate to="/home" /> },
      { index: true, path: "home", element: <HomePage /> },
      { index: true, path: "users/:userId", element: <UserPage /> },
      {
        path: "reservations",
        children: [
          {
            index: true,
            path: "",
            element: <ReservationsPage title={i18n.t('reservations_page_title_daily_timeline')} />,
          },
          {
            index: true,
            path: "arrivals",
            element: <ReservationsPage title={i18n.t('reservations_page_title_arrivals')} />,
          },
          {
            index: true,
            path: "departures",
            element: <ReservationsPage title={i18n.t('reservations_page_title_departures')} />,
          },
          {
            index: true,
            path: "owner_arrivals",
            element: <ReservationsPage title={i18n.t('reservations_page_title_owner_arrivals')} />,
          },
          {
            index: true,
            path: "check_in_requests",
            element: <ReservationsPage title={i18n.t('reservations_page_title_check_in_requests')} />,
          },
        ],
      },
      {
        path: "listings",
        children: [
          { index: true, path: "", element: <ListingsPage /> },
          { path: ":listingId", element: <ListingPage /> },
        ],
      },
      {
        path: "tasks",
        children: tasksRoutes,
      },
    ],
  },
  {
    path: "oauth2/signup",
    element: <RedirectWithToken />,
  },
  { path: "login", element: <Navigate to="/home" /> }, // This is here to support legacy shortcuts.
  { path: "404", element: <UnknownRoutePage /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default AppRouter;
