import { useState, useEffect, useCallback, useMemo } from 'react';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints: Record<Breakpoint, string> = {
  xs: '(max-width: 320px)',
  sm: '(max-width: 640px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 1024px)',
  xl: '(max-width: 1280px)',
  '2xl': '(min-width: 1280px)',
};

// Function to determine the current breakpoint
const getCurrentBreakpoint = (): Breakpoint => {
  for (const breakpoint of Object.keys(breakpoints) as Breakpoint[]) {
    if (window.matchMedia(breakpoints[breakpoint]).matches) {
      return breakpoint;
    }
  }
  return 'xs'; // Default to 'xs' if no match
};

// Custom hook to use the current breakpoint
const useBreakpoint = (): Breakpoint => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(getCurrentBreakpoint);

  const handleResize = useCallback(() => {
    setCurrentBreakpoint(getCurrentBreakpoint());
  }, []);

  useEffect(() => {
    // Set the initial breakpoint
    handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return useMemo(() => currentBreakpoint, [currentBreakpoint]);
};

export default useBreakpoint;
