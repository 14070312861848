/* eslint-disable no-lone-blocks */
import { t } from "i18next";
import { enUS, es } from "date-fns/locale";
import i18n from "./config";
import { ListingStatus } from "../app/models/constants";
import { TaskStatus } from "../app/utils/task-status";
import { TaskFormCleanSubTypes, TaskFormDropSubTypes, TaskFormInspectionSubTypes, TaskFormMaintenanceSubTypes, TaskFormPickupSubTypes, TaskFormShuttleServiceSubTypes, TaskFormType } from "../app/components/task-form";

// TRANSLATION SETUP NOTES
/*
https://github.com/i18next/react-i18next/issues/1601
https://locize.com/blog/react-i18next/#getting-started

FIRST INSTALL...
npm i i18next@23.0.1 --legacy-peer-deps

...THEN...
npm i react-i18next@13.0.0 --legacy-peer-deps

...THEN
npm i i18next-browser-languagedetector --legacy-peer-deps
*/

//TODO enum these, and move to a data/utils file
export enum supportedLocaleCode {
  en = "en",
  es = "es",
  ilo = "ilo",
  tl = "tl",
}

export const localeCodes: string[] = Object.values(supportedLocaleCode);
export const supportedLocaleNames = {
  en: "English",
  es: "Español",
  ilo: "Ilocano",
  tl: "Tagalog",
};

export function lookupDateFNSLocale(locale: string) {
  switch (locale) {
    case "en":
      return enUS;
    case "es":
      return es;
    //NOTE: IMPORTANT: DateFNS does not have every locale: https://github.com/orgs/date-fns/discussions/2724
    //                 We fall back to Spanish, but there are other options available if it becomes a priority.
    case "ilo":
    case "tl":
      return es;
    default: {
      console.warn("unsupported locale: " + locale);
      return enUS;
    }
  }
}

export function lookupDayOfWeekName(
  dayName: string,
  locale: string,
  useRelativeDays: boolean = false
) {
  const nameIn = dayName.trim().toLowerCase();
  switch (nameIn) {
    case "sunday":
      return t("date_week_0_sunday");
    case "monday":
      return t("date_week_1_monday");
    case "tuesday":
      return t("date_week_2_tuesday");
    case "wednesday":
      return t("date_week_3_wednesday");
    case "thursday":
      return t("date_week_4_thursday");
    case "friday":
      return t("date_week_5_friday");
    case "saturday":
      return t("date_week_6_saturday");
    default: {
      if (useRelativeDays) {
        switch (nameIn) {
          case "overdue":
            return t("task_state_overdue");
          case "today":
            return t("date_today");
          case "tomorrow":
            return t("date_tomorrow");
          default: {
            console.warn("invalid week day name (w/ relative): " + nameIn);
            return dayName;
          }
        }
      }
      console.warn("invalid week day name: " + nameIn);
      return dayName;
    }
  }
}

export function lookupListingStatus(status: string) {
  switch (status.trim().toLowerCase()) {
    case ListingStatus.Clean.toLowerCase(): return t('listings_filter_option_status_clean');
    case ListingStatus.Cleaning.toLowerCase(): return t('listings_filter_option_status_cleaning');
    case ListingStatus.Dirty.toLowerCase(): return t('listings_filter_option_status_dirty');
    case ListingStatus.Occupied.toLowerCase(): return t('listings_filter_option_status_occupied');
    case ListingStatus.Vacant.toLowerCase(): return t('listings_filter_option_status_vacant');

    default: {
      console.warn('unknown listing status: ' + status);
    }
  }
  return ''; // fallback
}

//LOC: Consolidate these w/ the existing group enums and types (of which there are several).
//     It's a bigger refactor than you'd expect, and it's tied into the
//     dynamic groups and client-side 'permissions'.
export type LocalizedGroupName =
  "Engineering"
  | "Guest Experience"
  | "Housekeepers"
  | "Housekeeping"
  | "Housemen"
  | "Operations Delivery"
  | "Owner Experience"
  | "Reservations"
  | "Shuttle Drivers"
  | "Special Projects";

export function lookupGroupName(assignee: LocalizedGroupName) {
  switch (assignee.toLowerCase().trim()) {
    case "engineering":
      return t('group_name_engineering');
    case "guest experience":
      return t('group_name_guest_experience');
    case "housekeepers":
      return t('group_name_housekeepers');
    case "housekeeping":
      return t('group_name_housekeeping');
    case "housemen":
      return t('group_name_housemen');
    case "operations delivery":
      return t('group_name_operations_delivery');
    case "owner experience":
      return t('group_name_owner_experience');
    case "reservations":
      return t('group_name_reservations');
    case "shuttle drivers":
      return t('group_name_shuttle_drivers');
    case "special projects":
      return t('group_name_special_projects');
    default: {
      //NOTE: This is currently overloaded to take in user names.
      //      If/when we consolidate groups into a single type, that can be changed. We'll pre-empt this
      //      with a check against assignee-type.
      //      For now, just drop out silently.
      // console.warn('unknown group name:', assignee);
    }
  }
  // no fallback, because this
  return assignee;
}

export function lookupMonthName(month: string) {
  switch (month.trim().toLowerCase()) {
    case 'january': return t('date_month_name_00_january');
    case 'february': return t('date_month_name_01_february');
    case 'march': return t('date_month_name_02_march');
    case 'april': return t('date_month_name_03_april');
    case 'may': return t('date_month_name_04_may');
    case 'june': return t('date_month_name_05_june');
    case 'july': return t('date_month_name_06_july');
    case 'august': return t('date_month_name_07_august');
    case 'september': return t('date_month_name_08_september');
    case 'october': return t('date_month_name_09_october');
    case 'november': return t('date_month_name_10_november');
    case 'december': return t('date_month_name_11_december');

    default: {
      console.warn('unknown month: ' + month);
    }
  }
  return ''; // fallback
}

export function lookupPropertyNoteTab(tabTitle: string) {
  switch (tabTitle) {
    case "Appliances":
      return "listings_property_notes_tab_appliances";
    case "Housekeeping":
      return "listings_property_notes_tab_housekeeping";
    case "Maintenance":
      return "listings_property_notes_tab_maintenance";
    default:
      return tabTitle;
  }
}

export function lookupPropertyView(view: string) {
  if (!view) {
    // console.warn('view is missing');
    return '';
  }
  const viewKey = view.trim().toLowerCase();
  switch (viewKey) {
    case 'bay view': return t('property_view_bay_view');
    case 'bay view elevated': return t('property_view_bay_view_elevated');
    case 'bay view ground floor': return t('property_view_bay_view_ground_floor');
    case 'deluxe oceanfront': return t('property_view_deluxe_oceanfront');
    case 'deluxe oceanview': return t('property_view_deluxe_oceanview');
    case 'elevated': return t('property_view_elevated');
    case 'garden': return t('property_view_garden');
    case 'garden elevated': return t('property_view_garden_elevated');
    case 'ground floor': return t('property_view_ground_floor');
    case 'garden ground floor': return t('property_view_garden_ground_floor');
    case 'garden grounded': return t('property_view_garden_grounded');
    case 'interior ground floor': return t('property_view_interior_ground_floor');
    case 'islandview': return t('property_view_islandview');
    case 'mountain gardenview': return t('property_view_mountain_gardenview');
    case 'mountainview': return t('property_view_mountainview');
    case 'mountainview penthouse': return t('property_view_mountainview_penthouse');
    case 'mountainview plus': return t('property_view_mountainview_plus');
    case 'oceanfront': return t('property_view_oceanfront');
    case 'oceanfront ground floor': return t('property_view_oceanfront_ground_floor');
    case 'oceanfront penthouse': return t('property_view_oceanfront_penthouse');
    case 'oceanview': return t('property_view_oceanview');
    case 'oceanview elevated': return t('property_view_oceanview_elevated');
    case 'oceanview ground floor': return t('property_view_oceanview_ground_floor');
    case 'oceanview penthouse': return t('property_view_oceanview_penthouse');
    case 'oceanview upper': return t('property_view_oceanview_upper');
    case 'partial oceanview': return t('property_view_partial_oceanview');
    case 'partial oceanview elevated': return t('property_view_partial_oceanview_elevated');
    case 'partial oceanview ground floor': return t('property_view_partial_oceanview_ground_floor');
    case 'pool view': return t('property_view_pool_view');
    case 'premier oceanview': return t('property_view_premier_oceanview');
    case 'resortview': return t('property_view_resortview');
    default: {
      console.error('unknown property view: ' + view + ' (' + viewKey + ')');
    }
  }
  return ''; // fallback
}

export function lookupReservationType(type: string) {
  switch (type) {
    case 'Guest': return t('reservations_type_filter_guest');
    case 'Owner': return t('reservations_type_filter_owner');
    case 'Guest of Owner': return t('reservations_type_filter_guest_of_owner');
    default: {
      console.warn('unknown reservation type: ' + type);
    }
  }
  return ''; // fallback
}

export function lookupTaskGroup(group: string) {
  switch (group.trim().toLowerCase()) {
    case TaskStatus.Completed.toLowerCase(): return t('task_status_completed');
    case TaskStatus.NotStarted.toLowerCase(): return t('task_status_not_started');
    case TaskStatus.InProgress.toLowerCase(): return t('task_status_in_progress');
    case TaskStatus.OnHold.toLowerCase(): return t('task_status_on_hold');
    case TaskStatus.Deferred.toLowerCase(): return t('task_status_deferred');
    case TaskStatus.Escalated.toLowerCase(): return t('task_status_escalated');
    default: {
      console.warn('unknown task status: ' + group);
    }
  }
  return ''; // fallback
}

export function lookupTaskPriority(priority: string) {
  switch (priority.trim().toLowerCase()) {
    case 'high': return t('task_priority_high');
    case 'low': return t('task_priority_low');
    case 'lowest': return t('task_priority_lowest');
    case 'normal': return t('task_priority_normal');
    case 'urgent': return t('task_priority_urgent');
    default: {
      console.warn('unknown task priority: ' + priority);
    }
  }
  return ''; // fallback
}

export function lookupTaskStatus(status: string) {
  switch (status.trim().toLowerCase()) {
    case TaskStatus.Completed.toLowerCase(): return t('task_status_completed');
    case TaskStatus.NotStarted.toLowerCase(): return t('task_status_not_started');
    case TaskStatus.InProgress.toLowerCase(): return t('task_status_in_progress');
    case TaskStatus.OnHold.toLowerCase(): return t('task_status_on_hold');
    case TaskStatus.Deferred.toLowerCase(): return t('task_status_deferred');
    case TaskStatus.Escalated.toLowerCase(): return t('task_status_escalated');
    default: {
      console.warn('unknown task status: ' + status);
    }
  }
  return ''; // fallback
}

export function lookupTaskSubType(type: TaskFormType, subType: string) {
  switch (type) {
    case 'Maintenance': {
      switch (subType as TaskFormMaintenanceSubTypes) {
        case 'Installation': return t('task_subtype_maintenance_installation');
        case 'Paint': return t('task_subtype_maintenance_paint');
        case 'Parts': return t('task_subtype_maintenance_parts');
        case 'Patch & Paint': return t('task_subtype_maintenance_patch_and_paint');
        case 'Repair': return t('task_subtype_maintenance_repair');
        case 'Replacement': return t('task_subtype_maintenance_replacement');
        case 'Service': return t('task_subtype_maintenance_service');
        case 'Other': return t('task_subtype_maintenance_other');
        default: {
          console.warn('unknown maintenance task subtype: ' + subType);
        }
      }
    }
    break;
    case 'Drop': {
      switch (subType as TaskFormDropSubTypes) {
        case 'Bedding': return t('task_subtype_drop_bedding');
        case 'Consumables': return t('task_subtype_drop_consumables');
        case 'Inventory': return t('task_subtype_drop_inventory');
        case 'Rentals': return t('task_subtype_drop_rentals');
        case 'Towels': return t('task_subtype_drop_towels');
        case 'Other' as any: return t('task_group_other'); // tacked on due to usage in prod
        default: {
          console.warn('unknown drop task subtype: ' + subType);
        }
      }
    }
    break;
    case 'Pickup': {
      switch (subType as TaskFormPickupSubTypes) {
        case 'Bedding': return t('task_subtype_pickup_bedding');
        case 'Consumables': return t('task_subtype_pickup_consumables');
        case 'Inventory': return t('task_subtype_pickup_inventory');
        case 'Rentals': return t('task_subtype_pickup_rentals');
        case 'Towels': return t('task_subtype_pickup_towels');
        default: {
          console.warn('unknown pickup task subtype: ' + subType);
        }
      }
    }
    break;
    case 'Clean': {
      switch (subType as TaskFormCleanSubTypes) {
        case 'Carpet Encapsulation': return t('task_subtype_clean_carpet_encapsulation');
        case 'Carpet Shampooing': return t('task_subtype_clean_carpet_shampooing');
        case 'Drop & Strip': return t('task_subtype_clean_drop_and_strip');
        case 'Deep Clean': return t('task_subtype_clean_deep_clean');
        case 'Departure Clean': return t('task_subtype_clean_departure_clean');
        case 'Drapery Cleaning': return t('task_subtype_clean_drapery_cleaning');
        case 'Full Clean': return t('task_subtype_clean_full_clean');
        case 'Glass & Lanai': return t('task_subtype_clean_glass_and_lanai');
        case 'Glass Restoration': return t('task_subtype_clean_glass_restoration');
        case 'Grill Cleaning': return t('task_subtype_clean_grill_cleaning');
        case 'Grout Cleaning': return t('task_subtype_clean_grout_cleaning');
        case 'Hood Cleaning': return t('task_subtype_clean_hood_cleaning');
        case 'Interior Tile Cleaning': return t('task_subtype_clean_interior_tile_cleaning');
        case 'Lanai Clean': return t('task_subtype_clean_lanai_clean');
        case 'Lanai Tile Cleaning': return t('task_subtype_clean_lanai_tile_cleaning');
        case 'Linens & Towels': return t('task_subtype_clean_linens_and_towels');
        case 'Linens': return t('task_subtype_clean_linens');
        case 'Tidy': return t('task_subtype_clean_tidy');
        case 'Towels': return t('task_subtype_clean_towels');
        case 'Upholstery Cleaning': return t('task_subtype_clean_upholstery_cleaning');
        case 'Other': return t('task_subtype_clean_other');
        default: {
          console.warn('unknown clean task subtype: ' + subType);
        }
      }
    }
    break;
    case 'Inspection': {
      switch (subType as TaskFormInspectionSubTypes) {
        case 'Maintenance': return t('task_subtype_inspection_maintenance');
        case 'Quality Assurance': return t('task_subtype_inspection_quality_assurance');
        case 'Final': return t('task_subtype_inspection_final');
        default: {
          console.warn('unknown inspection task subtype: ' + subType);
        }
      }
    }
    break;
    case 'Shuttle Service': {
      switch (subType as TaskFormShuttleServiceSubTypes) {
        case 'Guest': return t('task_subtype_shuttle_service_guest');
        case 'Owner': return t('task_subtype_shuttle_service_owner');
        case 'Other': return t('task_subtype_shuttle_service_other');
        default: {
          console.warn('unknown shuttle service task subtype: ' + subType);
        }
      }
    }
    break;
    default: {
      console.warn('unknown task type: ' + type);
    }
  }
  return ''; // fallback
}

export function lookupTaskType(type: TaskFormType) {
  switch (type.trim().toLowerCase()) {
    case 'Maintenance'.toLowerCase(): return t('task_group_maintenance');
    case 'Drop'.toLowerCase(): return t('task_group_drop');
    case 'Pickup'.toLowerCase(): return t('task_group_pickup');
    case 'Clean'.toLowerCase(): return t('task_group_clean');
    case 'Inspection'.toLowerCase(): return t('task_group_inspection');
    case 'Shuttle Service'.toLowerCase(): return t('task_group_shuttle_service');
    default: {
      console.warn('unknown task status: ' + type);
    }
  }
  return ''; // fallback
}

/*
 * Ensure all locales have the same set of translation keys, in the same order.
 * This is a dev-time only step, but it's important. It's very easy to make a mistake
 * while building the translated text objects.
 */
export function validateStringTables() {
  if (process.env.NODE_ENV === "production") {
    // Skip this check in production
    return;
  }

  console.warn('DEV ONLY: Validating localization string tables...');

  type I18nKeys = { [key: string]: any };

  const walkStrings = (object: I18nKeys, prefix: string = ""): string[] => {
    let result: string[] = [];
    Object.keys(object).forEach((key) => {
      const value = object[key];
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        result = result.concat(walkStrings(value, newKey));
      } else {
        result.push(newKey);
      }
    });
    return result;
  };

  const getAllStringsForLocale = (locale: string) => {
    const translations = i18n.getResourceBundle(locale, "translation");
    if (!translations) {
      console.warn(`No translations found for locale: ${locale}`);
      return [];
    }
    return walkStrings(translations);
  };

  const getAvailableLocales = () => {
    const locales = i18n.options?.resources
      ? Object.keys(i18n.options.resources)
      : [];
    return locales;
  };

  const locales = getAvailableLocales();
  const referenceLocale = "en";
  if (!locales.includes(referenceLocale)) {
    throw new Error("Reference locale not found: " + referenceLocale);
  }

  const referenceStringTable = getAllStringsForLocale(referenceLocale);

  for (const locale of locales) {
    if (locale === referenceLocale) {
      continue;
    }
    const localeStringTable = getAllStringsForLocale(locale);

    referenceStringTable.forEach((key) => {
      if (!localeStringTable.includes(key)) {
        throw new Error(
          "Missing translation key in locale: " +
            locale.toUpperCase() +
            " >> " +
            key
        );
      }
    });

    // cross-check in reverse; no extra strings
    localeStringTable.forEach((key) => {
      if (!referenceStringTable.includes(key)) {
        throw new Error(
          "Extra translation key in locale: " +
            locale.toUpperCase() +
            " >> " +
            key +
            "; either remove this key, or add it to the English string table"
        );
      }
    });

    // now check that the order of the keys match
    const referenceValues = Object.values(referenceStringTable);
    const localeValues = Object.values(localeStringTable);
    for (let i = 0; i < referenceValues.length; i++) {
      const referenceName = referenceValues[i];
      const localeName = localeValues[i];
      if (referenceName !== localeName) {
        throw new Error(
          "Translation key order mismatch between locales: " +
            locale.toUpperCase() +
            " >> " +
            referenceName +
            " !== " +
            localeName
        );
      }
    }
  }

  console.warn('DEV ONLY: ...string tables valid');
}
