import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// our string tables
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationILO from "./locales/ilo/translation.json";
import translationTL from "./locales/tl/translation.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // Add React bindings as a plugin.
  .use(initReactI18next)

  // Initialize the i18next instance.
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.
    //    lng: "en",
    //NOTE: This overrides URL and LS based settings, and should not be used. We want a fallback, only.

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here.
    fallbackLng: "en",

    // Enables useful output in the browser’s
    // dev console.
    debug: false,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
      ilo: {
        translation: translationILO,
      },
      tl: {
        translation: translationTL,
      },
    },
  });

export default i18n;
