import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import Modal from "./modal";
import Tooltip from "./tooltip";
import { useTranslation } from "react-i18next";

export const Attachment: React.FC<{
  file: File;
  onDelete?(fileName: string, attachment_id?: string): Promise<void>;
  loading: boolean,
  disableFullscreen?: boolean,
  attachment_id?: string,
}> = ({ file, onDelete, loading, disableFullscreen, attachment_id }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const [openFullScreenView, setOpenFullScreenView] = useState(false);
  const objRef = useRef<HTMLObjectElement>(null)
  const objFullScreenRef = useRef<HTMLObjectElement>(null)

  useEffect(() => {
    setPreviewLoading(true);
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        const dataUrl = e.target.result as string;
        setPreview(dataUrl);
      };
    };
    reader.readAsDataURL(file);
    setPreviewLoading(false);
  }, [file]);

  if (!file.type.includes('image')) {
    return (
      <div className="relative">
        {onDelete && <DeleteAttachmentButton attachment_id={attachment_id} loading={previewLoading} disabled={loading || previewLoading} fileName={file.name} onDelete={onDelete} setPreviewLoading={setPreviewLoading} />}
        <Tooltip title={t('ui_general_download') + file.type} bottom>
          <div className="w-full h-14 rounded-md outline outline-gray-300 dark:outline-gray-500 outline-1 hover:outline-gray-200 dark:hover:outline-gray-400">
            <a
              onClick={(e) => { e.stopPropagation() }}
              href={preview}
              className="cursor-pointer p-2 flex justify-start items-center dark:text-gray-200"
              type={file.type}
              datatype={file.type}
              download={file}
              title={file.name}
            >
              {file.type === 'application/pdf' && <span className="icon-[vscode-icons--file-type-pdf2] w-10 h-10 flex-none" />}
              {file.type !== 'application/pdf' && <span className="icon-[material-symbols--download] w-10 h-10 flex-none" />}
              <span className="truncate px-2">{file.name}</span>
            </a>
          </div>
        </Tooltip>
      </div>
    )
  }
  return (
    <div className="relative">
      {onDelete && <DeleteAttachmentButton attachment_id={attachment_id} loading={previewLoading} disabled={loading || previewLoading} fileName={file.name} onDelete={onDelete} setPreviewLoading={setPreviewLoading} />}
      <div
        className="w-full overflow-hidden relative h-14 flex items-center justify-center rounded-md outline outline-gray-300 dark:outline-gray-500 outline-1 hover:outline-gray-200 dark:hover:outline-gray-400">
        <object
          onClick={(e) => {
            e.stopPropagation();
            setOpenFullScreenView(true)
          }}
          ref={objRef}
          className='object-contain w-full h-full object-center'
          data={preview}
          type={file.type}
          name={file.name}
        >
          <a
            onClick={(e) => { e.stopPropagation() }}
            href={preview}
            className="cursor-pointer"
            type={file.type}
            datatype={file.type}
            download={file}
            title={file.name}
          >
            <div className="text-sm text-gray-500 flex items-center w-full h-full p-2 truncate">
              <span className="icon-[material-symbols--download] w-10 h-10 flex-none" />
              Click to download {file.name}
            </div>
          </a>
        </object>
      </div>
      {openFullScreenView && !disableFullscreen &&
        <Transition show={openFullScreenView}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={setOpenFullScreenView}>
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="relative flex min-h-full items-end justify-center text-center sm:items-center">
                <button type="button"
                  onClick={() => {
                    setOpenFullScreenView(false)
                  }}
                  className="fixed top-4 right-4 z-50">
                  <span className="icon-[material-symbols--close] w-6 h-6" />
                </button>
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative h-screen w-screen">
                    <object
                      ref={objFullScreenRef}
                      className="w-screen h-screen object-contain bg-white"
                      data={preview}
                      type={file.type}
                      name={file.name}>
                      <div className="text-sm text-gray-500 flex flex-col justify-center w-full h-full">
                        <div className="flex justify-center items-center">
                          <span className="icon-[material-symbols--download] w-8 h-8" />
                        </div>
                        <a
                          onClick={(e) => { e.stopPropagation() }}
                          href={preview}
                          className="cursor-pointer"
                          type={file.type}
                          datatype={file.type}
                          download={file.name}
                          title={file.name}
                        >
                          <span className="break-words text-center">
                            {t('task_attachment_download_prompt') + ' ' + file.name}
                          </span>
                        </a>
                      </div>
                    </object>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      }
    </div>
  );
};

const DeleteAttachmentButton = ({ disabled, setPreviewLoading, onDelete, fileName, attachment_id, loading }: { disabled: boolean, setPreviewLoading(a: boolean): void, onDelete(fileName: string, attachment_id?: string): Promise<void>, fileName: string, attachment_id?: string, loading: boolean }) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const { t } = useTranslation();
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className="z-50 flex items-center absolute right-0 p-1 rounded-full text-gray-500 hover:text-primary-dark dark:hover:text-white"
        onClick={() => { setOpenConfirm(true) }}>
        {!loading && <span className="icon-[material-symbols--close] w-4 h-4" />}
        {loading && <span className="icon-[uiw--loading] animate-spin w-4 h-4s" />}
      </button>
      <Modal
        open={openConfirm}
        setOpen={setOpenConfirm}
        alwaysShowX
        title={t('task_attachment_delete')}
        subTitle={t('task_attachment_delete_prompt') + ' ' + fileName + '?'}
        renderButton={
          <button
            className="w-full bg-primary-main rounded-md h-9 text-white hover:bg-primary-dark mt-2 sm:mt-0"
            type="button"
            onClick={async (e) => {
              e.stopPropagation();
              setPreviewLoading(true);
              await onDelete(fileName, attachment_id);
              setPreviewLoading(false);
              setOpenConfirm(false)
            }}>{t('ui_general_confirm')}</button>
        }
      />
    </>
  )
}
