import clsx from "clsx";
import React, { useId, useState } from "react";
import { useCache } from "../cache-provider";
import Modal from "./modal";
import TaskForm from "./task-form";
import { useTranslation } from "react-i18next";

// --------- Task Form w/ Request --------
export const PostTask: React.FC<{ onRefresh(a: ApiTask): Promise<void>; loading: boolean; float?: boolean; hideButton?: boolean, autoOpen?: boolean, autoOnClosed?: () => void, task?: ApiTask, followUpTask?: boolean, presetListingId?: string }> = ({ onRefresh, loading, float = false, hideButton = false, autoOpen = false, autoOnClosed, task, followUpTask, presetListingId }) => {
  const { t } = useTranslation();
  const id = useId();
  const [openForm, setOpenForm] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const { allQueues, users, listings } = useCache();
  // ------- assignees --------
  const {
    loading: loadingUsers,
    // error: usersError,
    data: usersData,
    initialized: usersInitialized,
    success: usersSuccess
  } = users;
  const userAssignees = usersSuccess ? usersData.map((userData: any) => ({ id: userData.id, label: userData.name, group: 'Users' })) : [];
  const {
    loading: loadingAllQueues,
    // error: allQueuesError,
    data: allQueuesData,
    // initialized: allQueuesInitialized,
    success: allQueuesSuccess
  } = allQueues;
  const allQueuesAssignees = allQueuesSuccess ? allQueuesData.map((queueData: any) => ({ id: queueData.id, label: queueData.name, group: 'Groups' })) : [];
  const assignees = [...userAssignees, ...allQueuesAssignees];
  const loadingAssignees = loadingUsers || loadingAllQueues;
  // ------- listings ---------
  const {
    loading: loadingListings,
    // error: listingsError,
    data: listingsData,
    initialized: listingsInitialized,
    success
  } = listings;
  const relatedTo = success ? listingsData.map((listing: any) => ({ id: listing.id, label: listing.name_detailed })) : [];

  const onClose = () => {
    setOpenForm(false);
    if (autoOnClosed) { autoOnClosed(); }
  };
  return (
    <>
      {!hideButton && <button id="all-task-create-task-btn" onClick={() => { setOpenForm(true) }}
        className={clsx(
          float ?
            'fixed bottom-3 right-4 z-20 bg-primary-main flex items-center justify-center rounded-full text-white hover:bg-secondary-main hover:scale-125 transition-all duration-200'
            : 'w-auto flex items-center ring-1 ring-primary-main dark:ring-white h-9 p-2 rounded-lg text-primary-main dark:text-white hover:text-secondary-main hover:ring-secondary-main dark:hover:text-secondary-main dark:hover:ring-secondary-main active:bg-secondary-main active:text-white'
        )}>
        <span className={clsx("icon-[gg--add] text-inherit", float ? 'h-12 w-12' : 'h-6 w-6 mr-1')} />
        <span className={clsx(!float && "hidden md:block", float && 'hidden')}>
          {t('task_widget_tab_new')}
        </span>
        <span className={clsx(!float && "md:hidden", float && 'hidden')}>
          {t('task_widget_tab_new_short')}
        </span>
      </button>}
      <Modal
        open={openForm || autoOpen} setOpen={() => onClose()}
        renderButton={
          <button
            disabled={loading || formSubmitting || !disableSave}
            form={`task-form-${id}`}
            type={task && task.id ? "button" : "submit"}
            onClick={() => { setShowConfirmEdit(true); }}
            className="w-full h-9 relative rounded-md bg-primary-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-main focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-main mt-2 sm:mt-0 disabled:bg-gray-500"
          >
            <span className={clsx('icon-[eos-icons--loading]', !loading && !formSubmitting ? 'hidden' : 'w-6 h-6 absolute top-1.5 left-1/2 right-1/2')} />
            <span className={clsx((loading || formSubmitting) && "hidden")}>
              {t('ui_general_save')}
            </span>
          </button>
        }>
        <TaskForm
          setShowConfirmEdit={setShowConfirmEdit}
          showConfirmEdit={showConfirmEdit}
          followUpTask={followUpTask}
          task={task}
          disableSave={setDisableSave}
          loading={formSubmitting}
          setLoading={setFormSubmitting}
          formId={`task-form-${id}`}
          onSuccess={(taskRes) => {
            onRefresh(taskRes);
            onClose();
          }}
          listings={relatedTo}
          listingsInitialized={listingsInitialized}
          users={assignees}
          usersInitialized={usersInitialized}
          loadingListings={loadingListings}
          loadingUsers={loadingAssignees}
          presetListingId={presetListingId} />
      </Modal>
    </>
  );
};
