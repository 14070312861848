import i18n from '../../i18n/config';

export function buildSecondaryActions(hasClaim: boolean | undefined, includeMove: boolean = true) {
  //NOTE: We carefully assemble these options in a very specific order (Claim, Defer, Move, Follow-up),
  //      but some of them are optional.
  const baseOptions = [{ title: i18n.t('task_command_defer'), action: 'deferTask', description: i18n.t('task_command_defer_explain') }];
  if (includeMove) {
    baseOptions.push({ title: i18n.t('task_command_move'), action: 'moveTask', description: i18n.t('task_command_move_explain') });
  }
  baseOptions.push({ title: i18n.t('task_command_follow_up'), action: 'followUpTask', description: i18n.t('task_command_follow_up_explain') });
  if (hasClaim === false) {
    return baseOptions;
  }
  return [{ title: i18n.t('task_command_claim'), action: 'claimTask', description: i18n.t('task_command_claim_explain') }].concat(baseOptions);
};
