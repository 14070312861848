export function checkTaskAssigneeMembership(
  task: ApiTask,
  userQueues: ApiQueue[]
) {
  if (task.assignee_type !== "Group") {
    return false;
  }
  for (let i = 0; i < userQueues.length; ++i) {
    const entry = userQueues[i];
    if (entry.id === task.assignee_id) {
      return true;
    }
  }
  return false;
}
