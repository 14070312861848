import {
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { supportedLocaleCode } from "../../../i18n/locUtils";
import LocaleMenuEntry from "../locale-menu-entry";

const LanguageSelectionMenu = () => {
  const { t } = useTranslation();
  return (
    <Menu as="div" className="relative">
      <MenuButton
        as="div"
        className="flex items-center justify-between mb-1 cursor-pointer"
      >
        {({ open }) => (
          <Label
            as="span"
            className={clsx(
              "flex items-center justify-between py-3 px-2 rounded-md w-full text-sm font-medium hover:bg-gray-100",
              open ? "bg-gray-100" : "dark:text-white hover:dark:text-inherit"
            )}
          >
            {t("ui_general_language")}
            <span
              className={clsx(
                "icon-[ic--round-keyboard-arrow-right] h-5 w-5",
                open ? "transform rotate-90" : ""
              )}
            />
          </Label>
        )}
      </MenuButton>
      <MenuItems>
        {({ open }) => {
          return (
            <div
              aria-expanded={open}
              className="animate-fade-in px-2 space-y-1"
            >
              <MenuItem as="div">
                {({ ...props }) => (
                  <LocaleMenuEntry
                    {...props}
                    code={supportedLocaleCode.en}
                    labelText={t("ui_general_language_english_us")}
                  />
                )}
              </MenuItem>
              <MenuItem as="div">
                {({ ...props }) => (
                  <LocaleMenuEntry
                    {...props}
                    code={supportedLocaleCode.es}
                    labelText={t("ui_general_language_spanish")}
                  />
                )}
              </MenuItem>
              <MenuItem as="div">
                {({ ...props }) => (
                  <LocaleMenuEntry
                    {...props}
                    code={supportedLocaleCode.ilo}
                    labelText={t("ui_general_language_ilocano")}
                  />
                )}
              </MenuItem>
              <MenuItem as="div">
                {({ ...props }) => (
                  <LocaleMenuEntry
                    {...props}
                    code={supportedLocaleCode.tl}
                    labelText={t("ui_general_language_tagalog")}
                  />
                )}
              </MenuItem>
            </div>
          );
        }}
      </MenuItems>
    </Menu>
  );
};

export default LanguageSelectionMenu;
