import i18n from "../../i18n/config";

export function createConfirmationModalText(action: ActionT) {
  switch (action) {
    case 'moveTask':
      return i18n.t('task_action_confirm_move');
    case 'resetTask':
      return i18n.t('task_action_confirm_reset');
    case 'pauseTask':
      return i18n.t('task_action_confirm_pause');
    case 'deferTask':
      return i18n.t('task_action_confirm_defer');
    case 'followUpTask':
      return i18n.t('task_action_confirm_follow_up');
    case 'endTask':
      return i18n.t('task_action_confirm_end');
    case 'editTask':
      return i18n.t('task_action_confirm_edit');
    case 'claimTask':
      return i18n.t('task_action_confirm_claim');
    case 'startTask':
    default:
      return i18n.t('task_action_confirm_start');
  }
};
