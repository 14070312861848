import { Dialog, DialogPanel, DialogTitle, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';
import React, { useCallback, useId, useState } from "react";
import useMakeSFRequest from '../request/salesforce/make-sf-call';
import { buildSecondaryActions } from '../utils/build-secondary-actions';
import { createConfirmationModalText } from '../utils/create-confirmation-modal-text';
import { createPrimaryOptions } from '../utils/create-primary-options';
import { useTaskDrawer } from './task-drawer';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { buildGA4EventParams, mapTaskActionToGA4EventName } from '../utils/analytics';
import { useAuth } from './auth/firebase-context';
import brand from '../config/brand';
import { useCache } from '../cache-provider';
import { useTranslation } from 'react-i18next';

export const TimedActions: React.FC<TimedActionsT> = ({ permission, task, onClick }) => {
  if (!task.is_timed) {
    return null;
  }
  if (!permission || !(permission.is_manageable_by_user || permission.is_my_task)) {
    return null;
  }
  return (
    <div id={`timed-buttons-${task.id}`}>
      {(task.status === 'Not Started' || task.status === 'On Hold') &&
        <button type='button' onClick={() => { onClick('startTask'); }} className='rounded-full flex items-center justify-center text-center bg-secondary-main hover:bg-primary-main text-white h-9 w-9 text-lg font-semibold transition-all duration-100'>
          <span className='icon-[mdi--play] h-7 w-7' />
        </button>}
      {task.status === 'In Progress' &&
        <button type='button' onClick={async () => { onClick('pauseTask'); }} className='rounded-full flex items-center justify-center text-center bg-secondary-main hover:bg-primary-main text-white h-9 w-9 text-lg font-semibold transition-all duration-100'>
          <span className='icon-[mdi--pause] h-7 w-7' />
        </button>}
    </div>
  );
};
export const SecondaryActions: React.FC<SecondaryActionsT> = ({ permission, task, onClick, selected, setSelected }) => {
  if (!permission) {
    return null;
  }
  const userHasPermission = task && task.type !== 'Shuttle Service' && (permission.is_manageable_by_user || permission.is_my_task)
  if (!userHasPermission) {
    if (!permission.is_claimable_by_user) {
      return null;
    }
    // fall-through to create a Claim button
  }
  if (!['On Hold', 'Not Started', 'In Progress'].includes(task.status)) {
    return null;
  }
  let inProgress = task.status === 'In Progress';
  if (!task.is_timed && task.status === 'Not Started') {
    inProgress = false;
  }
  const secondaryActionOptions = buildSecondaryActions(!inProgress && task.is_claimable && permission.is_claimable_by_user, permission.is_manageable_by_user);
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className='relative'>
          {/* LOC: ignore SR ONLY */}
          <Label className="sr-only">Secondary Actions</Label>
          <div className="inline-flex divide-x divide-white rounded-md shadow-sm">
            <button type='button' onClick={() => onClick(selected.action as ActionT)} className={clsx("inline-flex items-center gap-x-1.5 bg-success-dark px-3 py-2 hover:bg-success-main text-white shadow-sm", secondaryActionOptions.length > 1 ? 'rounded-l-md' : 'rounded-md')}>
              <p className="text-sm font-semibold whitespace-nowrap">{selected.title}</p>
            </button>
            {secondaryActionOptions.length > 1 &&
              <ListboxButton className="inline-flex items-center rounded-l-none rounded-r-md bg-success-dark p-2 hover:bg-success-main focus:outline-none focus:ring-0 focus:ring-primary-main">
                {/* LOC: ignore SR ONLY */}
                <span className="sr-only">Change Secondary Action</span>
                <span className="icon-[iconamoon--arrow-down-2] h-5 w-5 text-white" aria-hidden="true" />
              </ListboxButton>}
            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <ListboxOptions className="absolute origin-top-left bottom-full -left-10 h-fit w-44 divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {secondaryActionOptions.map((option) => (
                  <ListboxOption
                    key={option.title}
                    className={({ focus }) => clsx(
                      focus ? 'bg-success-dark text-white' : '',
                      !focus ? 'text-gray-900 dark:text-white' : '',
                      'cursor-default select-none p-4 text-sm'
                    )}
                    value={option}
                  >
                    {({ selected, focus }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={clsx('font-bold text-gray-900 dark:text-white', focus && 'text-white')}>{option.title}</p>
                          {selected ? (
                            <span className={focus ? 'text-white' : 'text-primary-main'}>
                              <span className="icon-[grommet-icons--checkbox-selected] h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={clsx(focus ? 'text-gray-200' : 'text-gray-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};
export const PrimaryActions: React.FC<PrimaryActionsT> = ({ permission, task, onClick, onEditTask, onPreEditTask }) => {
  const { t } = useTranslation();

  const [selectedPrimaryAction, setSelectedPrimaryAction] = useState({ title: t('task_command_complete'), action: "endTask", description: t('task_command_complete_explain') });
  if (!permission || !(permission.is_manageable_by_user || permission.is_my_task)) {
    return null;
  }
  const userHasManagerPermission = permission.is_manageable_by_user;

  if (task.is_closed) {
    return <button type='button' onClick={() => { onClick('resetTask'); }} className='rounded-lg flex items-center justify-center text-center bg-primary-main hover:bg-secondary-main text-white h-9 px-2 font-semibold transition-all duration-100'>
      {t('task_command_reset')}
    </button>;
  }
  if (task.status === 'Not Started') {
    if (task.is_timed) {
      if (userHasManagerPermission) {
        return (
          <>
            <button type='button' onClick={() => {
              onClick('editTask');
              onEditTask();
            }} className='rounded-lg flex items-center justify-center text-center bg-primary-main hover:bg-secondary-main text-white h-9 px-2 font-semibold transition-all duration-100'>
              {t('task_command_edit')}
            </button>
          </>);
      }
      return null;
    }
    // else we will show 'complete', we will also show 'edit' to managers.
  }
  else if (task.status === 'On Hold') {
    if (userHasManagerPermission) {
      return (
        <>
          <button type='button' onClick={() => {
            onClick('editTask');
            onEditTask();
          }} className='rounded-lg flex items-center justify-center text-center bg-primary-main hover:bg-secondary-main text-white h-9 px-2 font-semibold transition-all duration-100'>
            {t('task_command_edit')}
          </button>
        </>);
    }
    return null;
  }
  else if (task.status !== 'In Progress') {
    return null
  }
  // else we will show 'complete', we will also show 'edit' to managers.
  const primaryOptions = createPrimaryOptions(permission.is_manageable_by_user);
  return (
    <Listbox value={selectedPrimaryAction} onChange={setSelectedPrimaryAction}>
      {({ open }) => (
        <>
          {/* LOC: ignore SR ONLY */}
          <Label className="sr-only">Primary Actions</Label>
          <div className="relative">
            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <ListboxOptions className="absolute z-50 origin-top-right bottom-full h-fit w-fit divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {primaryOptions.map((option) => (
                  <ListboxOption
                    key={option.title}
                    className={({ focus }) => clsx(
                      focus ? 'bg-primary-main text-white' : '',
                      !focus ? 'text-gray-900 dark:text-white' : '',
                      'cursor-default select-none p-4 text-sm'
                    )}
                    value={option}
                  >
                    {({ selected, focus }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={clsx('font-bold text-gray-900 dark:text-white', focus && 'text-white')}>{option.title}</p>
                          {selected ? (
                            <span className={focus ? 'text-white' : 'text-primary-main'}>
                              <span className="icon-[grommet-icons--checkbox-selected] h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={clsx(focus ? 'text-gray-200' : 'text-gray-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
            <div className="inline-flex divide-x divide-white rounded-md shadow-sm">
              <button
                type='button'
                onClick={() => {
                  if (typeof onPreEditTask === 'function' && selectedPrimaryAction.action === 'editTask') {
                    onPreEditTask();
                  }
                  return onClick(selectedPrimaryAction.action as ActionT);
                }}
                className={clsx("inline-flex items-center gap-x-1.5 bg-primary-main px-3 py-2 hover:bg-secondary-main text-white shadow-sm", primaryOptions.length > 1 ? 'rounded-l-md' : 'rounded-md')}
              >
                <p className="text-sm font-semibold whitespace-nowrap">{selectedPrimaryAction.title}</p>
              </button>
              {primaryOptions.length > 1 &&
                <ListboxButton className="inline-flex items-center rounded-l-none rounded-r-md bg-primary-main p-2 hover:bg-secondary-main focus:outline-none focus:ring-0 focus:ring-primary-main">
                  {/* LOC: ignore SR ONLY */}
                  <span className="sr-only">Change Primary Action</span>
                  <span className="icon-[iconamoon--arrow-down-2] h-5 w-5 text-white" aria-hidden="true" />
                </ListboxButton>
              }
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
};
export const ConfirmActionDialog: React.FC<{
  task: ApiTask;
  action: ActionT;
  fullPageMode: boolean;
  refreshTask(taskData: any): Promise<void>;
  onClose(): void;
  setFollowUpTask(a: boolean): void;
  setOpenEditTask(a: boolean): void;
}> = ({ task, action, fullPageMode, refreshTask, onClose, setFollowUpTask, setOpenEditTask }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { allQueues: { data: queData, loading: queLoading, success: queSuccess } } = useCache();
  const id = useId();
  const request = useMakeSFRequest();
  const { setTaskRefreshCounter } = useTaskDrawer();
  const [loading, setLoading] = useState(false);
  const [escalate, setEscalate] = useState(false);
  const [showEscalate, setShowEscalate] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupToReassignTo, setGroupToReassignTo] = useState<string | null>(null);
  const taskActionRequest = useCallback(async () => {
    if (!task.id || !action) { return; };
    try {
      setLoading(true);

      if (action === 'followUpTask') {
        setFollowUpTask(true);
        setOpenEditTask(true);
        return;
      }
      if (action === 'editTask') {
        setOpenEditTask(true);
        return;
      }

      //[[ANALYTICS]]
      const analytics = getAnalytics();
      logEvent(
        analytics,
        mapTaskActionToGA4EventName(action),
        buildGA4EventParams(brand, user, null, task)); // optionally any form values can be added here

      let outgoingAction: string = action;
      if (escalate && action === "endTask") {
        outgoingAction = 'endTask&escalate=true';
      }

      let endpointPath = `/api/ohana/task/${task.id}?action=${outgoingAction}`;

      if (action === "moveTask" && groupToReassignTo) {
        endpointPath = `${endpointPath}&queue_id=${groupToReassignTo}`;
      }
      const response = await request({ endpointPath, method: 'PATCH' });
      if (response.error) {
        setError(t('error_generic'));
        setLoading(false);
        return;
      }

      if (!fullPageMode) {
        // force the list behind us to refresh, to reflect this update
        setTaskRefreshCounter((prev) => prev + 1);
      }
      await refreshTask((response as any).task);
      setLoading(false);
      onClose();
    } catch (e) {
        setError(t('error_generic'));
      setLoading(false);
      return;
    }
  }, [task.id, action, groupToReassignTo, request, fullPageMode, refreshTask, onClose, setFollowUpTask, setOpenEditTask, setTaskRefreshCounter, escalate]);

  if (action === 'endTask' && !showEscalate) {
    setShowEscalate(true);
  }

  return (
    <Transition show={!!action}>
      <Dialog id={`confirmation-action-${id}`} className="relative z-50" onClose={onClose}>
        <TransitionChild
          as='div'
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center p-0">
            <TransitionChild
              as='div'
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {action &&
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-screen sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <span className="icon-[heroicons-solid--exclamation] h-6 w-6 text-error-main" aria-hidden="true" />
                    </div>
                    <div className='flex flex-col'>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                          {t('task_dialog_confirm_action')}
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {createConfirmationModalText(action)}
                          </p>
                          {error &&
                            <p className="text-sm text-error-dark">
                              {error}
                            </p>}
                        </div>
                        {action === 'moveTask' && <div className='mt-2'>
                          <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                            {t('task_dialog_assign_to_team') + ':'}
                          </label>
                          <select
                            disabled={queLoading || !queSuccess}
                            id="location"
                            name="location"
                            className={clsx("mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6", !queSuccess && !queLoading && 'text-error-dark')}
                            value={groupToReassignTo || ''}
                            onChange={(event) => {
                              const group = event.currentTarget.value;
                              if (group) {
                                setGroupToReassignTo(group);
                                return;
                              }
                              setGroupToReassignTo(null);
                            }}
                          >
                            <option value=''>{queSuccess ? t('task_dialog_do_not_reassign') : t('task_dialog_error_get_groups')}</option>
                            {queSuccess && queData.map((data) => <option key={data.id} value={data.id}>{data.name}</option>)}
                          </select>
                        </div>}
                      </div>
                      {showEscalate &&
                        <div className='flex w-full mt-4'>
                          <fieldset>
                            <legend className="sr-only">{t('task_dialog_escalate_label')}</legend>
                            <div className="space-y-5">
                              <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                  <input
                                    onChange={(event) => {
                                      setEscalate(event.target.checked)
                                    }}
                                    defaultChecked={escalate}
                                    id="Escalate"
                                    name="Escalate"
                                    type="checkbox"
                                    aria-describedby="Escalate-description"
                                    className="h-4 w-4 rounded border-gray-300 text-primary-main focus:ring-primary-main"
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label htmlFor="Escalate" className="font-medium text-gray-900">
                                    {t('task_dialog_escalate_label')}
                                  </label>
                                  <p id="Escalate-description" className="text-gray-500">
                                    {t('task_dialog_escalate_prompt')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      autoFocus
                      type="button"
                      disabled={loading}
                      className="disabled:bg-gray-500 inline-flex w-full sm:w-24 justify-center rounded-md bg-error-dark px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-error-main sm:ml-3 focus:ring-0"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await taskActionRequest();
                      }}
                    >
                      {loading ? <span className='icon-[uiw--loading] animate-spin' /> : <span>{t('ui_general_confirm')}</span>}
                    </button>
                    <button
                      type="button"
                      disabled={loading}
                      className="disabled:text-gray-300 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onClose}
                      data-autofocus
                    >
                      {t('ui_general_cancel')}
                    </button>
                  </div>
                </DialogPanel>}
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>);
};
