import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function useDocumentTitle(title?: string, prevailOnUnmount = false) {
  const { t } = useTranslation();
  const defaultTitle = useRef(t('ui_general_app'));

  useEffect(() => {
    if (title) {
      document.title = decodeURIComponent(title) + ' | ' + t('ui_general_app');
    }
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle