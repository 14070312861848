import {
  Dialog,
  DialogPanel,
  Field,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSection,
  Switch,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../components/auth/firebase-context";
import LanguageSelectionMenu from "../components/local/LanguageSelectionMenu";
import { NavBar } from "../components/nav-bar";
import brand from "../config/brand";
import {
  getUserSettingsByUsername,
  postUserSettingsByUsername,
} from "../utils/user-settings";
import User from "../../classes/user";
import ProfileMenu from "./profile-menu";

export const AppLayout = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { pathname } = useLocation();
  const [userSettings, setUserSettings] = useState({ dark_mode: false });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);
  useEffect(() => {
    if (!user) {
      // console.warn('no user for persisted objects');
      return;
    }

    const fetchSettings = async () => {
      const currentSettings = await getUserSettingsByUsername(user.sf_user_id);
      setUserSettings(currentSettings);
    };

    fetchSettings();
  }, [user]);

  const SingleRenderedNav = useMemo(() => <NavBar />, []);

  return (
    <>
      <Helmet>
        <link id="brand-css" rel="stylesheet" href={brand.customCssUrl} />
        <link rel="manifest" href={brand.sitemanifestUrl} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={brand.appleIconUrl}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={brand.favIconUrl}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={brand.favIconUrl}
        />
        <link rel="shortcut icon" href={brand.favIconUrl} />
        <link rel="mask-icon" href={brand.appleIconUrl} color="#5994C5" />
      </Helmet>
      <div className={clsx(userSettings && userSettings.dark_mode && "dark")}>
        {/* Desktop Sidebar */}
        <Transition show={sidebarOpen}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <TransitionChild
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="icon-[gg--close-o] text-white " />
                    </button>
                  </div>
                </TransitionChild>
                <TransitionChild
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div>{SingleRenderedNav}</div>
                </TransitionChild>
              </DialogPanel>
            </div>
          </Dialog>
        </Transition>
        {/* Desktop Sidebar */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {SingleRenderedNav}
        </div>
        <div className="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-secondary-main px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 lg:hidden text-white"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="icon-[ic--outline-menu-open] lg:hidden w-8 h-8" />
          </button>
          <div className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
            <img
              src={brand.logoUrl}
              className={clsx(
                brand.code === "BVR" && "filter brightness-0 invert",
                "hidden sm:block h-8 w-auto "
              )}
            />
            <img
              src={brand.iconUrl}
              className="sm:hidden h-8 w-auto filter brightness-0 invert"
            />
          </div>
          {user && (
            <div className="flex flex-row space-x-4">
              <ProfileMenu
                user={user}
                logout={logout}
                menuButton={
                  <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-secondary-main focus:ring-offset-2 focus:ring-offset-primary-main">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      {user?.first_name && user?.last_name && (
                        <span className="font-medium leading-none text-white">
                          {user.first_name.charAt(0)}
                          {user.last_name.charAt(0)}
                        </span>
                      )}
                      {!user?.first_name ||
                        (!user?.last_name && (
                          <span className="font-medium leading-none text-white">
                            ME
                          </span>
                        ))}
                    </span>
                  </MenuButton>
                }
              />
            </div>
          )}
        </div>
        <main className="lg:pl-72">
          <div className="w-full dark:bg-gray-900">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};
