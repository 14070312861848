/* eslint-disable no-lone-blocks */
import i18n from "../../i18n/config";
import { supportedLocaleCode } from "../../i18n/locUtils";

export const dynamicLocManager: Record<'task', Record<string, Record<supportedLocaleCode, any>>> = {
  task: {}
};

export const feedLocCache: Record<supportedLocaleCode, Map<string, string>> = {
  en: new Map<string, string>(),
  es: new Map<string, string>(),
  ilo: new Map<string, string>(),
  tl: new Map<string, string>()
};

export class DynamicTranslator {
  public hasTextToTranslate = false;
  private fieldNames: string[] = [];
  private valuesEN: string[] = [];
  private englishMap = new Map<string, string>();

  AddTask(task: ApiTask) {
    const taskJSON = JSON.parse(JSON.stringify(task));
    for (const k in taskJSON) {
      if (!checkTaskString(k, taskJSON[k])) {
        continue;
      }
      this.fieldNames.push(k)
      this.valuesEN.push(taskJSON[k]);
      this.englishMap.set(k, taskJSON[k]);
    }
    this.hasTextToTranslate = this.fieldNames.length > 0;
    if (this.hasTextToTranslate) {
      // append these strings from the related object, as applicable
      const relatedChildStrings = parseTaskRelatedObject(task);
      if (relatedChildStrings !== null) {
        relatedChildStrings.forEach((entry) => {
          this.fieldNames.push(entry.field);
          this.valuesEN.push(entry[supportedLocaleCode.en]);
          this.englishMap.set(entry.field, entry[supportedLocaleCode.en]);
        });
      }
    }
  }

  LocalizeTask(valuesTranslated: string[], task: ApiTask, locale: supportedLocaleCode) {
    // apply these strings, and update the dynamic string table
    const localizedMap = new Map<string, string>();
    const prefix = lookupTaskRelatedPrefix(task);
    this.fieldNames.forEach((fieldName, i) => {
      localizedMap.set(fieldName, valuesTranslated[i]);
      // set the translated text to the task model in memory
      if (fieldName.startsWith(prefix)) {
        // this is a related-object field; update that object
        const field = fieldName.slice(prefix.length);
        (task.related as any)[field] = valuesTranslated[i];
      }
      else {
        (task as any)[fieldName] = valuesTranslated[i];
      }
    });
    (task as any)._textLocale = locale;
    return localizedMap;
  }

  CountStrings() {
    return this.valuesEN.length;
  }

  GetEnglishMap() {
    return this.englishMap;
  }

  GetEnglishValues() {
    return this.valuesEN;
  }
}

export interface StringTableEntry {
  field: string;
  [supportedLocaleCode.en]: string;
};

const StringTableTaskPrefixCase = 'case:';
const StringTableTaskPrefixListing = 'list:';
const StringTableTaskPrefixReservation = 'resv:';

export function lookupTaskRelatedPrefix(task: ApiTask): string {
  if (!task.related_type || !task.related) {
    return '';
  }
  switch (task.related_type.toLowerCase().trim())
  {
    case 'case':            return StringTableTaskPrefixCase;
    case 'listing__c':      return StringTableTaskPrefixListing;
    case 'reservation__c':  return StringTableTaskPrefixReservation;
    default: {
      console.warn('Unsupported related type on lookup: ' + task.related_type);
      return '';
    }
  }
};

export function parseTaskRelatedObject(task: ApiTask) {
  if (!task.related_type || !task.related) {
    return null;
  }
  // gather the case's string fields for dynamic translation
  const relatedJSON = JSON.parse(JSON.stringify(task.related));
  const englishTable: StringTableEntry[] = [];
  const prefix = lookupTaskRelatedPrefix(task);
  for (const k in relatedJSON) {
    switch (task.related_type.toLowerCase().trim())
    {
      case 'case': {
        if (!checkCaseString(k, relatedJSON[k])) {
          continue;
        }
      }
      break;
      case 'listing__c': {
        if (!checkListingString(k, relatedJSON[k])) {
          continue;
        }
      }
      break;
      case 'reservation__c': {
        if (!checkReservationString(k, relatedJSON[k])) {
          continue;
        }
      }
      break;
      default: {
        console.warn('Unsupported related type: ' + task.related_type);
        return null;
      }
    }
    englishTable.push({ field: prefix + k, [supportedLocaleCode.en]: relatedJSON[k] });
  }
  return englishTable;
};

export function restoreTask(originalTextMap: Map<string, string>, task: ApiTask, locale: supportedLocaleCode) {
  const prefix = lookupTaskRelatedPrefix(task);
  originalTextMap.forEach((value: string, key: string) => {
    // set the original text to the task model in memory
    if (key.startsWith(prefix)) {
      // this is a related-case field; update that object
      const field = key.slice(prefix.length);
      (task.related as any)[field] = value;
    }
    else {
      (task as any)[key] = value;
    }
  });
  (task as any)._textLocale = locale;
}

export function checkCaseString(field: string, value: any) {
  if (typeof value !== 'string') {
    return false;
  }
  // blacklist fields that are problematic or never displayed
  if (field === 'type'
      || field === 'owner_name'
      || field === 'owner_id'
      || field === 'name'
      || field === 'last_modified_date'
      || field === 'id'
      || field === 'description'
      || field === 'unit_id'
      || field === 'next_check_in'
      || field === 'check_out'
      || field === 'priority'
      || field === 'origin'
      || field === 'last_modified_date'
      || field === 'comments'
      || field === 'case_number') {
    return false;
  }
  return true;
};

export function checkListingString(field: string, value: any) {
  if (typeof value !== 'string') {
    return false;
  }
  // blacklist fields that are problematic or never displayed
  if (field === 'type'
    || field === 'name'
    || field === 'id'
    || field === 'unit_view'
    || field === 'unit_id'
    || field === 'resort_name'
    || field === 'next_check_in'
    || field === 'name_detailed'
    || field === 'listing_status'
    || field === 'check_out'
    || field === 'lessor_name') {
    return false;
  }
  return true;
};

export function checkReservationString(field: string, value: any) {
  if (typeof value !== 'string') {
    return false;
  }
  // blacklist fields that are problematic or never displayed
  // if (field === 'type' << CHECK HELPER
  if (field === 'name'
    || field === 'id'
    || field === 'unit_view'// << HELPER
    || field === 'unit_id'
    || field === 'next_check_in'
    || field === 'name_detailed'
    || field === 'listing_status'// << HELPER
    // || field === 'stage' << CHECK HELPER
    || field === 'previous_check_out'
    // || field === 'next_check_in_type' << CHECK HELPER
    || field === 'listing_id'
    // || field === 'guest_type' << CHECK HELPER
    || field === 'guest_name'
    || field === 'guest_id'
    || field === 'check_out'
    || field === 'check_in'
    || field === 'guest_phone'
    || field === 'check_out_time'
    || field === 'check_out_date'
    || field === 'check_in_time'
    || field === 'check_in_date'
    || field === 'booking_date') {
    return false;
  }
  return true;
};

export function checkTaskString(field: string, value: any) {
  if (typeof value !== 'string') {
    return false;
  }
  // blacklist fields that are problematic or never displayed
  if (field === 'created_date'
    || field === 'last_modified_date'
    || field === 'due_time'
    || field === 'due_datetime'
    || field === 'due_date'
    || field === 'start_time'
    || field === 'completed_datetime'
    || field === 'id'
    || field === 'assignee_type'
    || field === 'related_id'
    || field === 'reporter_id'
    || field === 'assignee_id'
    || field === 'priority'
    || field === 'related_type'
    || field === 'status'
    || field === 'sub_type'
    || field === 'type') {
    return false;
  }
  return true;
};

function writeLocTaskStorageKey(task: ApiTask) {
  if (!task) {
    throw new Error('task missing on loc key create');
  }
  return 'loc-task-' + task.id;
};

type LocPreference = { timestamp: number, locale: supportedLocaleCode };

const LOC_TIME_LIMIT_MS = 15 * 60 * 1000;

export function clearTaskLocPreference(task: ApiTask) {
  const lsKey = writeLocTaskStorageKey(task);
  localStorage.removeItem(lsKey);
};

function getTaskLocPreference(task: ApiTask): LocPreference | null {
  try {
    const lsKey = writeLocTaskStorageKey(task);
    const pref = localStorage.getItem(lsKey);
    if (pref) {
      const parsed: LocPreference = JSON.parse(pref);
      if (parsed) {
        const delta = Date.now() - parsed.timestamp;
        if (delta < LOC_TIME_LIMIT_MS) {
          // console.warn('cache hit', parsed)
          return parsed;
        }
        // cache expired
        localStorage.removeItem(lsKey);
        // console.log('cache miss, reset')
      }
    }
    return null;
  }
  catch (e) {
    console.warn('Failed to parse task locale preference', e);
    return null;
  }
};

export function setTaskLocPreference(task: ApiTask, locale: supportedLocaleCode) {
  const lsKey = writeLocTaskStorageKey(task);
  const info: LocPreference = { timestamp: Date.now(), locale };
  localStorage.setItem(lsKey, JSON.stringify(info));
};

export function checkTaskLocalized(task: ApiTask): boolean {
  if (!task) {
    console.warn('task missing on loc check');
    return false;
  }
  if (i18n.resolvedLanguage === supportedLocaleCode.en) {
    return false;
  }
  const preference = getTaskLocPreference(task);
  if (!preference || !preference.locale) {
    return false;
  }
  if (preference.locale === supportedLocaleCode.en) {
    return false;
  }
  return true;
}

export function resetToDefaultText(task: ApiTask, feedMessages: FeedItemT[]): string {
  if (!task) {
    console.warn('task missing on loc reset');
    return '';
  }
  // we are restoring the original, untranslated strings
  if (!dynamicLocManager
    || !dynamicLocManager.task
    || !dynamicLocManager.task[task.id]
    || !dynamicLocManager.task[task.id][supportedLocaleCode.en]) {
    return i18n.t('task_translate_error_bad_state');
  }
  const originalTextMap = dynamicLocManager.task[task.id][supportedLocaleCode.en];
  restoreTask(originalTextMap, task, supportedLocaleCode.en);

  feedMessages.forEach((item: any, i: number) => {
    const englishFeed = feedLocCache[supportedLocaleCode.en].get(item.id);
    if (englishFeed !== undefined) {
      item.body = englishFeed;
    }
  });

  return '';
}

export function updateLocalizedTaskModel(task: ApiTask): boolean {
    if (!task) {
      console.warn('task model missing on update for loc');
      return false;
    }
    if (!checkTaskLocalized(task)) {
      return false;
    }

    // the task was translated previously
    if (dynamicLocManager
          && dynamicLocManager.task
          && dynamicLocManager.task[task.id]
          && dynamicLocManager.task[task.id][i18n.resolvedLanguage as supportedLocaleCode]) {
      // we have these strings from an earlier translation; reapply them
      restoreTask(dynamicLocManager.task[task.id][i18n.resolvedLanguage as supportedLocaleCode],
                  task,
                  i18n.resolvedLanguage as supportedLocaleCode);

      return true;
    }

    //NOTE: We _would_ support this case if we had strings in persistent storage, but for now
    //      they're only in memory. We have no strings to apply.
    clearTaskLocPreference(task);
    return false;
};