import i18n from "../../i18n/config";

export function createBedroomsLabel(numBeds: number | undefined) {
  switch (numBeds) {
    case undefined:
      return "- " + i18n.t('listings_card_subtitle_bedrooms').toLowerCase();
    case 0:
      return i18n.t('listings_filter_option_beds_studio');
    case 1:
      return 1 + ' ' + i18n.t('listings_card_subtitle_bedroom').toLowerCase();
    default:
      return numBeds + ' ' + i18n.t('listings_card_subtitle_bedrooms').toLowerCase();
  }
};
