
//NOTE: There is duplication here w/ "ApiListingStatus". It would be nice to
//      merge them, but it's a major refactor, due to the decalration files
//      arranged in an interface hierarchy.
export enum ListingStatus {
  Occupied = "Occupied",
  Dirty = "Dirty",
  Cleaning = "Cleaning",
  Clean = "Clean",
  Vacant = "Vacant"
};

//NOTE: The Task model is more complex than the Listing model.
//      It has a class, and several string utils here: src/classes/task/task-strings.ts
