import { captureException } from "@sentry/browser";
import { FallbackProps } from "react-error-boundary";
import useDocumentTitle from "../../utils/use-doc-title";
import PageError from "./page-error";
import { useTranslation } from "react-i18next";

export function ErrorBoundaryFallback(props: FallbackProps) {
  const { t } = useTranslation();
  useDocumentTitle(t('ui_general_error'));

  // send this to Sentry
  const errorText = props.error && props.error.message ? props.error.message : t('error_page_error_fallback');
  captureException(props.error ? props.error : new Error(errorText));

  return (
    <PageError
      statusMessage={t('error_page_message')}
      details={t('error_page_detail')}
      includeNavBackButton={false}
      resetErrorBoundary={props.resetErrorBoundary}
    />
  );

  /*SHELVED: Automatic reset feature.

  const [timeoutID, setTimeoutID] = React.useState<NodeJS.Timeout | null>(null);
  const [resetUsed, setResetUsed] = React.useState(false);

  const errorText = props.error && props.error.message ? props.error.message : 'Unknown';
  captureException(props.error ? props.error : new Error(errorText));

  const devMode = environment.mrrEnv !== 'production';
  const errorColor = '#733';
  const resetDelayMS = devMode ? 999000 : 5000;

  if (timeoutID !== null) {
    if (resetUsed) {
      clearInterval(timeoutID);
      setTimeoutID(null);
    }
    // else wait for the timer
  }
  else if (!resetUsed) {
    // create a timer that will (eventually) attempt to invoke the reset
    const newTimerID = setTimeout(() => {
      if (!resetUsed) {
        setResetUsed(true);
        props.resetErrorBoundary();
        if (timeoutID !== null) {
          clearInterval(timeoutID);
          setTimeoutID(null);
        }
      }
    },
    resetDelayMS);

    // save this, to be cleared later
    setTimeoutID(newTimerID);
  }

  return (
    <div style={{ fontSize: '1.2rem', padding: '20% 15% 0', textAlign: 'center' }}>
      <div>A problem has occurred, and the app needs to reset.</div>
      <br />
      <div style={{ fontSize: '1.0rem', color: '#644' }}>
        Error: <i>{errorText}</i>
      </div>
      <br />
      <br />
      <div style={{
        backgroundColor: errorColor,
        borderRadius: '2.5px',
        color: '#fff',
        display: 'flex',
        fontSize: '1.1rem',
        justifyContent: 'center',
        padding: '5px'
      }}
      >
        The app will reset in {(resetDelayMS / 1000).toFixed(0)} seconds...
      </div>
      <br />
      <br />
      <div style={{ display: 'flex', fontSize: '1.25rem', justifyContent: 'center' }}>
        {devMode &&
          <button
            disabled={resetUsed}
            style={{
              backgroundColor: errorColor,
              borderRadius: '6px',
              color: '#fff',
              fontSize: '1.25rem',
              maxWidth: '400px',
              padding: '10px 20px'
            }}
            onClick={props.resetErrorBoundary}
          >
            Reset Now
          </button>
        }
      </div>
    </div>
  )
  */
};
