import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  MenuButton,
} from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useCache } from "../cache-provider";
import brand from "../config/brand";
import { navigationByBrand } from "../config/path-config";
import ProfileMenu from "../layout/profile-menu";
import { getUserSettingsByUsername } from "../utils/user-settings";
import { useAuth } from "./auth/firebase-context";

const queueBasedNames = [
  "Engineering",
  "Housekeeping",
  "Guest Experience",
  "Owner Experience",
  "Shuttle Drivers",
];
export function NavBar() {
  const { user, logout } = useAuth();
  const { myQueues } = useCache();
  const { pathname } = useLocation();
  const [nav, setNav] = useState(structuredClone(navigationByBrand(brand)));
  const handleClick = () => {
    // tell each nav item whether it's the current page
    const navClone = structuredClone(nav);
    navClone.forEach((v) => {
      const rootOfHRef = v.href.split("?")[0];
      v.current = pathname === rootOfHRef;
      if (v.children) {
        v.children.forEach((c) => {
          const rootOfHRef = c.href.split("?")[0];
          c.current = pathname === rootOfHRef;
          if (pathname === rootOfHRef) {
            v.current = true;
          }
        });
      }
      // console.log('CURRENT?', v.current, location.pathname, '===', rootOfHRef, '|', v.href);
    });
    setNav(navClone);
  };
  const myQueueNames = myQueues.data.map((q: any) => q.name);
  useEffect(() => {
    handleClick();
  }, [pathname]);
  return (
    <div className="flex grow h-full flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 dark:text-white px-6">
      <div className="flex h-16 shrink-0 items-center bg-secondary-main -mx-6 px-6">
        {/* This is the top of the desktop-mode side menu */}
        <Link to="/">
          <img
            className={clsx(
              brand.code === "BVR" && "filter brightness-0 invert",
              "h-8 w-auto"
            )}
            src={brand.logoUrl}
            alt="logo icon"
          />
        </Link>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {nav.map((item) => {
                if (queueBasedNames.includes(item.name)) {
                  // this menu item is queue-based, so we need to check if the user is in the queue
                  if (!myQueueNames || !myQueueNames.includes(item.name)) {
                    return null;
                  }
                  // this menu item is allowed for this user
                }
                return (
                  <li key={item.name}>
                    {!Array.isArray(item?.children) ? (
                      <Link
                        to={item.href}
                        onClick={handleClick}
                        className={clsx(
                          item.current
                            ? "bg-gray-100 dark:bg-gray-700"
                            : "hover:bg-gray-100 dark:hover:bg-gray-700",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-100"
                        )}
                      >
                        {item.icon && (
                          <span
                            aria-hidden="true"
                            className={clsx(
                              item.icon,
                              "h-6 w-6 shrink-0 text-gray-400 "
                            )}
                          />
                        )}
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure as="div">
                        <DisclosureButton
                          onClick={handleClick}
                          className={clsx(
                            item.current
                              ? "bg-gray-100 dark:bg-gray-700"
                              : "hover:bg-gray-100 dark:hover:bg-gray-700",
                            "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 dark:text-gray-100"
                          )}
                        >
                          {item.icon && (
                            <span
                              aria-hidden="true"
                              className={clsx(
                                item.icon,
                                "h-6 w-6 shrink-0 text-gray-400"
                              )}
                            />
                          )}
                          {item.name}
                          <span className="icon-[ic--round-keyboard-arrow-right] w-4 h-4 group-data-[open]:rotate-90 group-data-[open]:text-gray-500" />
                        </DisclosureButton>
                        <DisclosurePanel as="ul" className="mt-1 px-2">
                          {Array.isArray(item?.children) &&
                            item.children.map((subItem) => (
                              <li key={subItem.name}>
                                {/* 44px */}
                                <Link
                                  to={subItem.href}
                                  onClick={handleClick}
                                  className={clsx(
                                    subItem.current
                                      ? "bg-gray-100 font-semibold dark:bg-gray-700"
                                      : "hover:bg-gray-100 dark:hover:bg-gray-700",
                                    "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 dark:text-gray-100"
                                  )}
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
          {user && (
            <li className="hidden lg:block -mx-6 mt-auto sticky bottom-0 bg-gray-50 dark:bg-gray-800">
              <ProfileMenu
                user={user}
                logout={logout}
                menuButton={
                  <MenuButton className="relative w-full flex items-center gap-x-4 px-6 py-4 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      {user?.first_name && user?.last_name && (
                        <span className="font-medium leading-none text-white">
                          {user.first_name.charAt(0)}
                          {user.last_name.charAt(0)}
                        </span>
                      )}
                      {!user?.first_name ||
                        (!user?.last_name && (
                          <span className="font-medium leading-none text-white">
                            ME
                          </span>
                        ))}
                    </span>
                    <span aria-hidden="true">{user.name}</span>
                  </MenuButton>
                }
              />
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
