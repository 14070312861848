import { initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";

const mrrEnv = (() => {
  let mrrEnvironment =
    process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-production"
      ? "production"
      : process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-staging"
      ? "staging"
      : "development";
  return mrrEnvironment;
})();

const environment = {
  port: process.env.PORT!,
  env: process.env.NODE_ENV!,
  mrrEnv,
};

const firebase_api = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID!,
  appId: process.env.REACT_APP_FIREBASE_APPID!,
};

export const firebaseApp = initializeApp(firebase_api);

export const firestore = getFirestore(firebaseApp);

export const auth = getAuth(firebaseApp);

export const CLOUD_FUNCTIONS = getFunctions(firebaseApp);

// auth.setPersistence(browserLocalPersistence);

export const analytics = getAnalytics(firebaseApp);

export function CheckLocalDev() {
  return (
    environment.env === "development" &&
    process.env.REACT_APP_LOCAL_DEV === "true"
  );
}

if (CheckLocalDev() && false) {
  // These emulators let us use Google Cloud Functions in local dev (to avoid one loop blowing our budget).
  const urlBase = "localhost";
  const authPort = 3003;
  const firestorePort = 3002;
  const functionsPort = 3000;

  connectAuthEmulator(auth, `http://${urlBase}:${authPort}`);

  connectFirestoreEmulator(firestore, urlBase, firestorePort);

  connectFunctionsEmulator(CLOUD_FUNCTIONS, urlBase, functionsPort);
}
