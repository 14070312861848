import * as React from "react";
import { useTranslation } from "react-i18next";
import { LoginPage } from "../../page/login-page";
import useMakeSFRequest from "../../request/salesforce/make-sf-call";
import { AppLoader } from "../app-loader";
import { useAuth } from "./firebase-context";

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { t } = useTranslation();
  const { user, initialized } = useAuth();

  //NOTE: "Preemptive refresh" is here to prevent attempting to refresh many times simultaneously.
  //      Previously this would happen at startup, because ~5 calls can go out (e.g. one-time fetches
  //      for things like groups and listings).

  const preRefreshRequest = useMakeSFRequest();
  const [preRefreshExecuted, setPreRefreshExecuted] = React.useState(false);
  const [refreshPending, setRefreshPending] = React.useState(false);

  // local helper callback
  const preRefreshSFToken = React.useCallback(
    async () => {
      if (!user) {
        console.warn('attempting to preemptively refresh without a user');
        return;
      }
      if (preRefreshExecuted) {
        console.warn('redundant attempt to preemptively refresh');
        return;
      }
      if (refreshPending) {
        // console.log('refreshPending');
        return;
      }
      setRefreshPending(true);
      //NOTE: We send 'true' here to force the hook to refresh our access token.
      //      The payload is unused (but kept in place here to prevent confusion on every other SF call).
      const response = await preRefreshRequest({ endpointPath: 'unused', method: 'GET' }, true);
      if (response.error) {
        console.warn('preemptive refresh received an error', response.error);
      }
      else if (response.status !== 200) {
        console.warn('preemptive refresh failed', response.status);
      }
      else {
        // console.log('preemptive refresh success');
      }
      setPreRefreshExecuted(true);
      setRefreshPending(false);
    }, [preRefreshExecuted, preRefreshRequest, refreshPending, user]);

  // this useEffect is here to reset the preemptive refresh on logout
  React.useEffect(() => {
    if (!user && preRefreshExecuted) {
      // logout detected; reset
      setPreRefreshExecuted(false);
      // console.log('LOGOUT DETECTED');
    }
    else {
      // console.log('NON LOGOUT UPDATE');
    }
  }, [user, preRefreshExecuted]);

  if (!initialized) {
    return (
      <AppLoader message={t("auth_loader_loading")} />
    );
  }

  if (!user) {
    return (<LoginPage />);
  }

  if (!preRefreshExecuted) {
    preRefreshSFToken();
    return (
      <AppLoader message={t("auth_loader_sf_oauth")} />
    );
  }

  return (<> {children} </>);
}
