import {
  Field,
  Label,
  Menu,
  MenuItem,
  MenuItems,
  MenuSection,
  Switch,
} from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import User from "../../classes/user";
import LanguageSelectionMenu from "../components/local/LanguageSelectionMenu";
import useBreakpoint from "../utils/use-breakpoint";
import {
  getUserSettingsByUsername,
  postUserSettingsByUsername,
} from "../utils/user-settings";

type ProfileMenuProps = {
  user: User;
  logout: () => void;
  menuButton: React.ReactNode;
};

const ProfileMenu = ({ user, logout, menuButton }: ProfileMenuProps) => {
  const breakpoint = useBreakpoint();
  const isDesktop = ["xl", "2xl"].includes(breakpoint);
  const { t } = useTranslation();
  const [userSettings, setUserSettings] = useState({ dark_mode: false });
  useEffect(() => {
    const fetchSettings = async () => {
      const currentSettings = await getUserSettingsByUsername(user.sf_user_id);
      setUserSettings(currentSettings);
    };
    fetchSettings();
  }, [user]);

  return (
    <Menu as="div" className="relative">
      <MenuSection>
        <div>{menuButton}</div>
        <MenuItems
          className={clsx(
            "absolute z-50 w-48 rounded-md bg-white dark:bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in",
            isDesktop ? "bottom-12 right-2" : "top-9 right-0"
          )}
        >
          <div className="animate-fade-in-fast p-2">
            <MenuItem>
              {({ focus }) => (
                <Link
                  to={"users/" + user.sf_user_id}
                  className={clsx(
                    "flex items-center justify-between py-3 px-2 rounded-md w-full text-sm font-medium hover:bg-gray-100 cursor-pointer",
                    focus ? "bg-gray-100" : "dark:text-white"
                  )}
                >
                  {t("user_page_menu_profile")}
                  {/* TBD: (MOBILE VIEW) do we want to show this on focus only? */}
                  {/* {focus && <span className="icon-[ic--round-keyboard-arrow-right] h-5 w-5" />} */}
                  <span className="icon-[ic--round-keyboard-arrow-right] h-5 w-5" />
                </Link>
              )}
            </MenuItem>

            <LanguageSelectionMenu />

            <MenuItem>
              {({ focus }) => (
                <Field
                  as="div"
                  className={clsx("flex items-center justify-between p-2")}
                >
                  <Label
                    as="span"
                    className="mr-3 text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {t("user_page_menu_dark_mode")}
                  </Label>
                  <Switch
                    checked={userSettings.dark_mode}
                    onChange={async (dark) => {
                      document.documentElement.classList.toggle("dark", dark);
                      document.documentElement.classList.toggle(
                        "tw-dark",
                        dark
                      );
                      setUserSettings((prev) => ({
                        ...prev,
                        dark_mode: dark,
                      }));
                      await postUserSettingsByUsername(user.sf_user_id, {
                        ...userSettings,
                        dark_mode: dark,
                      });
                    }}
                    className={clsx(
                      userSettings.dark_mode
                        ? "bg-primary-main"
                        : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-main focus:ring-offset-2"
                    )}
                  >
                    {/* LOC: ignore SR ONLY */}
                    <span className="sr-only">Use setting</span>
                    <span
                      className={clsx(
                        userSettings.dark_mode
                          ? "translate-x-5"
                          : "translate-x-0",
                        "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    >
                      <span
                        className={clsx(
                          userSettings.dark_mode
                            ? "opacity-0 duration-100 ease-out"
                            : "opacity-100 duration-200 ease-in",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <span className="icon-[line-md--sunny-filled-loop]" />
                      </span>
                      <span
                        className={clsx(
                          userSettings.dark_mode
                            ? "opacity-100 duration-200 ease-in"
                            : "opacity-0 duration-100 ease-out",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true"
                      >
                        <span className="icon-[line-md--sunny-outline-to-moon-loop-transition]" />
                      </span>
                    </span>
                  </Switch>
                </Field>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <div className="p-2">
                  <button
                    type="button"
                    onClick={async () => {
                      await logout();
                    }}
                    className={clsx(
                      focus ? "bg-error-main dark:text-white" : "",
                      "bg-error-dark rounded block px-4 py-2 text-sm text-white w-full"
                    )}
                  >
                    {t("user_page_log_out")}
                  </button>
                </div>
              )}
            </MenuItem>
          </div>
        </MenuItems>
      </MenuSection>
    </Menu>
  );
};

export default ProfileMenu;
