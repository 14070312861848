
//NOTE: "Maintenance" and "Inspecting" were used here, but not in the formal
//      type for the Listing model.
//      The listing model uses a declarations file, so we can't give it the enum
//      with its runtime benefits, like these switch cases. Worse, ApiListing
//      is stacked with other types types in a hierarchy, so we can't just move
//      it to a .ts file without a major refactor.
//
//TODO: Determine whether the enum should include these two. Remember that
//      it's also used for available filters.

import { ListingStatus } from "../models/constants";

export const listingStatusColor = (status: string) => {
  switch (status) {
    case ListingStatus.Clean:
      return "primary-main";
    case ListingStatus.Vacant:
    case ListingStatus.Occupied:
      return "text-white ring-secondary-main bg-secondary-main";
    case ListingStatus.Dirty:
    case "Maintenance":
      return "error-main";
    case ListingStatus.Cleaning:
    case "Inspecting":
      return "warning-main";
    default:
      return "primary-main";
  }
};
