import { createBedroomsLabel } from './create-bedrooms-label';
import { createBathroomsLabel } from './create-bathrooms-label';
import { lookupPropertyView } from '../../i18n/locUtils';

export function createListingDescription(listing: ApiListing) {
  let label = createBedroomsLabel(listing.bedrooms) +
    "・" +
    createBathroomsLabel(listing.bathrooms);
  if (typeof listing.unit_view === 'string' && listing.unit_view !== "") {
    label += '・' + lookupPropertyView(listing.unit_view);
  }
  return label;
};
