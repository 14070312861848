import clsx from "clsx";
import i18n from "i18next";
import React from "react";
import { supportedLocaleCode } from "../../i18n/locUtils";

interface LocaleMenuEntryProps {
  code: supportedLocaleCode;
  labelText: string;
  focus: boolean;
  close: () => void;
  disabled: boolean;
}

const LocaleMenuEntry: React.FC<LocaleMenuEntryProps> = ({
  code,
  labelText,
  focus,
  // close,
  disabled,
}) => {
  const handleClick = () => {
    i18n.changeLanguage(code);
    // close();
    window.location.reload();
  };
  const isSelectedLanguage = i18n.resolvedLanguage === code;
  return (
    <button
      type="button"
      disabled={disabled}
      aria-selected={isSelectedLanguage}
      value={code}
      className={clsx(
        "flex items-center justify-between py-3 px-2 rounded-md w-full text-sm hover:bg-gray-100",
        isSelectedLanguage || focus
          ? "bg-gray-100 font-medium"
          : "dark:text-white"
      )}
      onClick={handleClick}
    >
      {labelText}
    </button>
  );
};

export default LocaleMenuEntry;
