import i18n from "../../i18n/config";

export const createPrimaryOptions = (userHasManagerPermission: boolean) => {
  if (userHasManagerPermission) {
    return [
      { title: i18n.t('task_command_complete'), action: 'endTask', description: i18n.t('task_command_complete_explain') },
      { title: i18n.t('task_command_edit'), action: 'editTask', description: i18n.t('task_command_edit_explain') }
    ];
  }
  return [
    { title: i18n.t('task_command_complete'), action: 'endTask', description: i18n.t('task_command_complete_explain') },
  ];
};
