import clsx from "clsx";
import { format } from "date-fns";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useId,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import { lookupDateFNSLocale } from "../../i18n/locUtils";
import Modal from "./modal";

const ReservationModalContext = createContext<{
  openReservationModal: ApiReservation | null;
  setOpenReservationModal(a: ApiReservation | null): void;
  reservationRefreshCounter: number;
  setReservationRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
}>({
  openReservationModal: null,
  setOpenReservationModal: () => undefined,
  reservationRefreshCounter: 0,
  setReservationRefreshCounter: () => 0,
});

export const useReservationModal = () => {
  const context = useContext(ReservationModalContext);
  if (!context) {
    throw new Error("Hook must be called within ReservationModalProvider.");
  }
  return context;
};

const ReservationModalProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const id = useId();
  const [openReservationModal, setOpenReservationModal] =
    useState<ApiReservation | null>(null);
  const [reservationRefreshCounter, setReservationRefreshCounter] =
    useState<number>(0);
  const values = useMemo(
    () => ({
      openReservationModal,
      setOpenReservationModal,
      reservationRefreshCounter,
      setReservationRefreshCounter,
    }),
    [
      openReservationModal,
      setOpenReservationModal,
      reservationRefreshCounter,
      setReservationRefreshCounter,
    ]
  );
  return (
    <ReservationModalContext.Provider value={values}>
      <ReservationModal
        id={`reservation-modal-${id}`}
        reservation={openReservationModal}
        onClose={() => {
          setOpenReservationModal(null);
        }}
      />
      {children}
    </ReservationModalContext.Provider>
  );
};

const ReservationModal: React.FC<{
  id: string;
  reservation: ApiReservation | null;
  onClose(): void;
}> = ({ id, reservation, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      id={id}
      open={!!reservation}
      setOpen={onClose}
      hideButtons
      alwaysShowX={false}
    >
      <div className="absolute top-2 right-2">
        <button type="button" className="-m-2.5 p-2.5" onClick={onClose}>
          <span className="icon-[material-symbols--close] text-gray-500 dark:text-white hover:text-gray-900 w-5 h-5" />
        </button>
      </div>
      {reservation && (
        <div className="flex flex-row gap-2 items-center">
          <h4 className="text-lg font-semibold">{reservation.name}</h4>
          <span
            className={clsx(
              "inline-flex items-center flex-shrink rounded-full px-2 py-1 text-xs font-medium text-white ring-1 ring-inset text-center align-middle",
              typeof reservation.type === "string" &&
                reservation.type.toLowerCase().includes("owner")
                ? "bg-primary-main ring-primary-main"
                : "bg-secondary-main ring-secondary-main"
            )}
          >
            {reservation.type}
          </span>
        </div>
      )}
      {reservation && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
          <div className="flex flex-col items-start">
            <h6 className="font-semibold">{t('reservations_modal_label_guest')}</h6>
            <span>{reservation.guest_name}</span>
          </div>
          <div className="flex flex-col items-start">
            <h6 className="font-semibold">{t('task_detail_label_phone')}</h6>
            <a
              href={`tel:${reservation.guest_phone}`}
              aria-label="Call guest"
              className="text-primary-main hover:underline hover:text-secondary-main"
            >
              {reservation.guest_phone}
            </a>
          </div>
          <div className="flex flex-col items-start">
            <h6 className="font-semibold">{t('reservations_modal_label_property')}</h6>
            <span>{reservation.name_detailed}</span>
          </div>
          <div className="flex flex-col items-start">
            <h6 className="font-semibold">{t('reservations_modal_label_status')}</h6>
            <span>{reservation.listing_status}</span>
          </div>
          {reservation.check_in && (
            <div className="flex flex-col items-start">
              <h6 className="font-semibold">{t('reservations_modal_label_check_in')}</h6>
              <span>
                {format(new Date(reservation.check_in), 'M/dd/yyyy h:mm a', { locale: lookupDateFNSLocale(i18n.language) })}
              </span>
            </div>
          )}
          {reservation.check_out && (
            <div className="flex flex-col items-start">
              <h6 className="font-semibold">{t('reservations_modal_label_check_out')}</h6>
              <span>
                {format(new Date(reservation.check_out), 'M/dd/yyyy h:mm a', { locale: lookupDateFNSLocale(i18n.language) })}
              </span>
            </div>
          )}
          {reservation.has_check_in_requests && (
            <div className="flex flex-col items-start">
              <h6 className="font-semibold">{t('reservations_modal_label_check_in_requests')}</h6>
              <ol>
                {reservation.check_in_requests.split(";").map((text, index) => (
                  <li key={`check_in_request-${index}`}>{text}</li>
                ))}
              </ol>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ReservationModalProvider;