import { httpsCallable } from "@firebase/functions";
import { CLOUD_FUNCTIONS } from "../config/firebase/app";
import { FirebaseError } from "firebase/app";
import i18n from "../../i18n/config";

export const defaultErrorMessage = i18n.t('auth_menu_error_connection') ||
  "There was a problem. Please check your connection.";

export interface AuthInfo {
  email: string;
}

type GCFResponse = {
  success: boolean;
};

export type GCFErrorResponse = GCFResponse & {
  error: Error;
};

export type ConfirmOTPResponse = GCFResponse & {
  confirmed: boolean;
  customToken: string | null;
  message: string;
};

export type EmailOTPResponse = GCFResponse & {
  anything: any;
};

export type SignInMethodsResponse = GCFResponse & {
  signInMethods: SignInMethods;
};

export type TranslationResponse = GCFResponse & {
  translation: string | string[];
};

export type VerifyUserResponse = GCFResponse & {
  sfUser: AuthInfo;
};

export interface SignInMethods {
  email?: string;
  methods: string[];
  phone?: string;
}

enum OTPResult {
  Confirmed = "confirmed",
  Expired = "expired",
  Failed = "failed",
}

// GCF endpoints

export const confirmOneTimePassword = async (
  email: string,
  code: string
): Promise<ConfirmOTPResponse | GCFErrorResponse> => {
  if (!email) {
    return { success: false, error: new Error("Valid email required.") };
  }
  if (!code) {
    return { success: false, error: new Error("Six-digit code required.") };
  }
  const cloudFunction = httpsCallable(
    CLOUD_FUNCTIONS,
    "confirmOneTimePassword"
  );
  const cloudResult = await cloudFunction({ email, code })
    .then((response) => {
      validateResponse(response);
      let outcomeText = "This code is not correct. Please try again.";
      if ((response.data as any).success) {
        switch ((response.data as any).result) {
          case OTPResult.Confirmed:
            outcomeText = "Code confirmed.";
            break;
          case OTPResult.Expired:
            outcomeText = "This code has expired.";
            break;
          case OTPResult.Failed:
            break;
          // default set above
        }
      }
      const confirmed = (response.data as any).success;
      return {
        success: true,
        confirmed: confirmed,
        message: outcomeText,
        customToken: confirmed ? (response.data as any).customToken : null,
      };
    })
    .catch((error: Error) => {
      return handleError(error);
    });

  return cloudResult;
};

export const emailOneTimePassword = async (
  email: string
): Promise<EmailOTPResponse | GCFErrorResponse> => {
  if (!email) {
    return { success: false, error: new Error("Valid email required.") };
  }
  const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, "emailOneTimePassword");
  const cloudResult = await cloudFunction({ email, brand: "brandgoeshere" })
    .then((response) => {
      validateResponse(response);
      return { success: true, anything: true };
    })
    .catch((error: Error) => {
      return handleError(error);
    });

  return cloudResult;
};

export const getSignInMethods = async (
  email: string
): Promise<SignInMethodsResponse | GCFErrorResponse> => {
  if (!email) {
    return { success: false, error: new Error("Please enter a valid email.") };
  }
  const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, "getSignInMethods");
  const cloudResult = await cloudFunction({ email })
    .then((response) => {
      validateResponse(response);
      const data: SignInMethods = response.data as SignInMethods;
      if (!data.methods || (!data.email && !data.phone)) {
        return {
          success: false,
          error: new Error("No sign-in methods received."),
        };
      }
      return { success: true, signInMethods: data };
    })
    .catch((error: Error) => {
      return handleError(error);
    });

  return cloudResult;
};

export const translateText = async (
  text: string | string[],
  language: string
): Promise<TranslationResponse | GCFErrorResponse> => {
  if (!text) {
    return { success: false, error: new Error("Text required.") };
  }
  if (text === '') {
    return { success: false, error: new Error("String is empty.") };
  }
  if (Array.isArray(text) && text.length <= 0) {
    return { success: false, error: new Error("String array is empty.") };
  }
  if (!language) {
    return { success: false, error: new Error("Language required.") };
  }
  const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, "translateText");
  const cloudResult = await cloudFunction({ text, language })
    .then((response) => {
      validateResponse(response);
      if (!(response.data as any).translation) {
        throw new Error("Translation missing/empty.");
      }
      return { success: true, translation: (response.data as any).translation };
    })
    .catch((error: Error) => {
      return handleError(error);
    });

  return cloudResult;
};

export const verifyUser = async (
  email: string,
  username: string
): Promise<VerifyUserResponse | GCFErrorResponse> => {
  if (!email && !username) {
    return {
      success: false,
      error: new Error("Valid email or username required."),
    };
  }
  const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, "verifyUser");
  const cloudResult = await cloudFunction({ email, username })
    .then((response) => {
      validateResponse(response);
      const user: AuthInfo = (response.data as any).user as AuthInfo;
      if (!user.email) {
        return {
          success: false,
          error: new Error("Invalid user auth info received."),
        };
      }
      return { success: true, sfUser: user };
    })
    .catch((error: Error) => {
      return handleError(error);
    });

  return cloudResult;
};

// local helpers

function handleError(error: Error) {
  console.error("cloud function caught", error);
  if (error instanceof FirebaseError && error.code === "functions/internal") {
    return { success: false, error: new Error(defaultErrorMessage) };
  }
  return { success: false, error };
}

function validateResponse(response: any) {
  if (typeof response.data !== "object" || response.data === null) {
    throw new Error("Invalid response format.");
  }
  if ((response.data as any).status !== 200) {
    if ((response.data as any).error) {
      throw (response.data as any).error;
    }
    throw new Error("Unknown response error.");
  }
}
