import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type PageErrorProps = {
  statusMessage: string,
  details: string,
  includeNavBackButton: boolean
  resetErrorBoundary?: (...args: Array<unknown>) => void;
};

const navLinkClassName = "rounded-md bg-primary-main px-3.5 py-2.5 text-sm font-semibold text-primary-contrastText hover:text-secondary-contrastText shadow-sm hover:bg-secondary-main focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-main";

export default function PageError(props: PageErrorProps) {
  const { t } = useTranslation();
  const { statusMessage, details, includeNavBackButton } = props;
  const navigate = useNavigate();

  let message = statusMessage;
  if (!message) {
    message = t('error_generic'); // default
  }

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{message}</h1>
          {details &&
            <p className="mt-6 text-base leading-7 text-gray-500">{details}</p>
          }
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to='/home' className={navLinkClassName} >{t('control_basic_route_to_dashboard')}</Link>
          </div>
          {includeNavBackButton &&
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                className="border text-white bg-primary-main hover:bg-secondary-main rounded-md p-2"
                onClick={() => {
                  if (props.resetErrorBoundary) {
console.log('RESET ERROR BOUNDARY')
                    props.resetErrorBoundary();
                  }
                  navigate(-2);
                }}
              >
                {t('ui_general_back')}
              </button>
            </div>
          }
        </div>
      </main>
    </>
  );
};
