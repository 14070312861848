import i18n from "../../i18n/config";

export function createBathroomsLabel(numBaths: number | undefined) {
  if (numBaths === undefined) {
    return ' - ' + i18n.t('listings_card_subtitle_bathrooms').toLowerCase();
  }
  if (numBaths > 1) {
    return numBaths + ' ' + i18n.t('listings_card_subtitle_bathrooms').toLowerCase();
  }
  return numBaths + ' ' + i18n.t('listings_card_subtitle_bathroom').toLowerCase();
};
